import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const ContentWithMediaGrid = loadable(() => import('/src/components/PageBuilder/layouts/standard/ContentWithMediaGrid'))

const ContentWithMultiMedia = ({ backgroundColour, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            contentWithMultiMedia {
                                fieldGroupName
                                contentWithMultiMedia {
                                    addTitleArea
                                    title
                                    content
                                    itemsRepeater {
                                        mediaType
                                        image {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 250)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                        video
                                    }
                                    addCtaOrExpansionToggle
                                    buttonType
                                    expandContent {
                                        initialItems
                                        moreItemsButtonText
                                        lessItemsButtonText
                                    }
                                    callToAction {
                                        target
                                        title
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.contentWithMultiMedia.contentWithMultiMedia

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title
        }

        return (
            <ContentWithMediaGrid
                key={index}
                backgroundColour={backgroundColour}
                titleArea={titleArea}
                content={componentData.content}
                itemsRepeater={componentData.itemsRepeater}
                addCtaOrExpansionToggle={componentData.addCtaOrExpansionToggle}
                buttonType={componentData.buttonType}
                expandContent={componentData.expandContent}
                callToAction={componentData.callToAction}
                className={'c-content-with-multi-media'}
                utm={utm}
            />
        )
    })
}

ContentWithMultiMedia.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ContentWithMultiMedia.defaultProps = {
    backgroundColour: 'gradient'
}

export default ContentWithMultiMedia

export const query = graphql`
    fragment ContentWithMultiMediaForPageBuilder on WpPage_Acfpagebuilder_Layouts_ContentWithMultiMedia {
        fieldGroupName
        backgroundColour
    }
`
